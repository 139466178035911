.training-view__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    background: white;
}

.training-view__content h1, .training-view__content__short {
    text-align: center;
}
