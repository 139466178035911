.modules {}

.modules__col > .ant-card {
    border: 1px solid #dddddd!important;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.modules__col > .ant-card > .ant-card-body > h1 {
    color: #555!important;
    font-size: 1.1rem;
    text-align: center;
}

.modules__col > .ant-card > .ant-card-body > h3 {
    color: #777!important;
    font-size: 0.85rem;
    text-align: center;
}

.modules__card-icon {
    font-size: 2rem;
    display: block;
}