.news {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.news-title {
    background: white!important;
}

.news__info {
    flex: 1;
    align-self: stretch;
}

.news-wrapper {
    background: white!important;
    flex: 1;
    align-self: stretch;
    border: 1px solid #CCCCCC!important;
}

.news-editor {
    background: white;
    min-height: 20vh;
    padding: 0 1rem;
}
