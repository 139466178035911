.software__card {
    background: white;
    text-align: justify;
    width: calc(25% - 1rem);
    align-self: stretch;
}

.software__card p {
    color: #333333;
}

@media screen and (max-width: 768px) {
    .software__card {
        width: calc(100vw - 2rem)!important;
    }
}
