.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    background: #EEEEEE;
}

.login__container {
    height: calc(100vh - 15rem);
    width: calc(100vw - 40rem);
    display: flex;
    flex-direction: row;
    background: #F5F5F5;
    border-radius: 1rem;
}

.login__form {
    flex: 1;
    align-self: stretch;
    padding: 2rem;
}

.login__hero {
    flex: 1;
    align-self: stretch;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login__hero img {
    width: 480px;
    height: 480px;
}

.login__form {
    background: #FFFFFF;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login__form-wrapper {
    width: 60%;
}

.login__form-wrapper h3 {
    font-weight: 500;
}

.login__form-form {
    min-width: 100%!important;
}

.login__hero {
    background: rgb(209,82,46)!important;
    background: linear-gradient(353deg, rgba(209,82,46,1) 0%, rgba(66,22,49,1) 100%)!important;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
}

.mobile-only-img {
    width: 50%;
    margin-left: 25%;
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
    .login__container {
        height: 100vh;
        width: 100vw;
    }
    .login__form {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .login__hero {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .login__container {
        flex-direction: column;
        height: 100vh;
        width: 100vw;
    }
    .login__form {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .login__form-wrapper {
        width: 95%;
    }
    .login__hero {
        display: none;
    }
}

@media screen and (min-width: 1400px) {
    .mobile-only-img {
        display: none;
    }
}
