.holiday__header-card {
    background: white;
}

.holiday__total-days {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.holiday__total-days-cards {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.holiday__total-days-cards > .ant-card {
    flex: 1;
    align-self: stretch;
}

.holiday__form-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}

.holiday__form-date-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}

.holiday__form-date-row-sub {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}

.holiday__form-date-row-sub > * {
    flex: 1;
    align-self: stretch;
}

.holiday__form-date-row > .ant-form-item {
    flex: 1;
    align-self: stretch;
}

.holiday__form-date-row > .ant-picker {
    width: 100%;
}

.holiday__form-row > .ant-form-item {
    flex: 1;
    align-self: stretch;
}

.holiday__form-bank-holidays {
    width: calc(50% - 0.5rem);
    margin-bottom: 1rem;
}

.holiday__form-bank-holidays h4 {
    margin-bottom: 0.5rem;
    font-weight: 500;
}

@media (max-width: 768px) {
    .holiday__form-bank-holidays {
        width: 100%;
    }

    .holiday__form-date-row, .holiday__form-date-row-sub, .holiday__form-row, .holiday__total-days-cards {
        flex-direction: column;
    }
}
