.aiassistant__sider, .aiassistant__content, .aiassistant__footer {
    background: none !important;
}

.aiassistant__sider-entry {
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    max-width: 300px;
    width: 100%;
    overflow: hidden;
    display: inline-block !important;
}

.aiassistant__footer {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 0.5rem !important;
}

.aiassistant__content {
    padding: 0 0 0 0.5rem !important;
    margin-bottom: 0.5rem;
}

.aiassistant__content-chatbox {
    min-height: 100% !important;
}

.aiassistant__content {
    align-self: stretch !important;
    flex: 1;
}

.aiassistant {
    height: calc(100vh - 520px);
}

.aiassistant__wrapper {
    min-height: 100% !important;
    max-height: 100%!important;
    overflow: auto;
}

.aiassistant__sider-entry:hover {
    background: rgba(255, 255, 255, 0.8) !important;
}

.aiassistant__sider .ant-list-header {
    padding: 0 !important;
}

.aiassistant__message--bot, .aiassistant__message--user {
    border-radius: 0.5rem;
    padding: 0.5rem !important;
    margin-bottom: 0.5rem;
}

.aiassistant__message--user {
    width: 80%;
    background: #ece7ea;
}

.aiassistant__message--bot {
    width: 80%;
    margin-left: 20%;
    background: #f5dcd5;
}

.aiassistant__message--new-chat {
    width: 100%;
    text-align: center;
}
