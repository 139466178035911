.apps-modal__info {
    flex: 1;
    align-self: stretch;
}

.apps-modal__edit-form {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
}

.apps-modal__edit-form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.apps-modal__edit-form > * {
    flex: 1!important;
    display: flex;
    flex-direction: column;
}

.apps-modal__content {
    margin-top: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
