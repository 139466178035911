.user-add {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.user-add__form-field {
    background: white!important;
}

.user-add__info {
    flex: 1;
    align-self: stretch;
}

.user-add__form {
    align-self: stretch;
}

.user-add__form-row {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: stretch;
}

.user-add__form-field-wrapper {
    flex: 1;
    align-self: stretch;
}

@media screen and (max-width: 768px) {
    .user-add__form-row {
        flex-direction: column;
    }
}
