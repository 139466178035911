@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body, h1, h2, h3, h4, h5, p {
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.layout {
    min-height: 100vh;
    background: rgb(209, 82, 46) !important;
    background: linear-gradient(353deg, rgba(209, 82, 46, 1) 0%, rgba(66, 22, 49, 1) 100%) !important;
    background-size: 100vw 206px !important;
    background-repeat: no-repeat !important;
}

.layout-home {
    min-height: 100vh;
    background: rgb(209, 82, 46) !important;
    background: linear-gradient(353deg, rgba(209, 82, 46, 1) 0%, rgba(66, 22, 49, 1) 100%) !important;
    background-size: 100vw 206px !important;
    background-repeat: no-repeat !important;
}

.layout__header {
    display: flex;
    flex-direction: column;
    padding: 0 10vw !important;
    background: none !important;
}

.layout__header-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.layout__header-logo {
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.layout__header-logo-img {
    height: 2rem;
    max-width: 200px;
    margin-top: 1rem;
    cursor: pointer;
}

.layout__header-menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    background: none !important;
}

.content {
    margin: 0 10vw;
}

.content-welcome {
    font-size: 2rem;
    color: white;
    text-align: center;
}

.content-searchbar {
    margin: 1rem 15vw;
    width: 50vw !important;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2rem;
    align-self: center;
    border: 1px solid #AAA;
}

.site-search {
    left: 25vw !important;
    max-width: 50vw !important;
    min-width: 50vw !important;
    width: 50vw !important;
}

.searchbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.searchbar img {
    width: 200px;
}

.content-searchbar input {
    background: rgba(255, 255, 255, 0);
    padding: 0.5rem !important;
    color: #222 !important;
}

.content-searchbar input::placeholder {
    color: #555 !important;
}

.ant-menu-sub {
    background: #49000c !important;
}

.content-options {
    padding: 0 10vw;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.content-options__card-icon {
    font-size: 3rem;
}

.content-options > .ant-card:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.content-options > .ant-card {
    border-radius: 0 !important;
    flex: 1 0;
    align-self: stretch;
    height: 184px;
    margin: 1.5vh 0;
    transition: height 0.2s ease-in-out, margin 0.2s ease-in-out;
    border: 1px solid #dddddd !important;
}

.active-underline {
    box-shadow: inset 0 -5px 0 #D1522E !important;
}

.content-options > .ant-card:hover {
    height: 208px !important;
    border-radius: 8px !important;
    margin: 0;
}

.content-options > .ant-card > .ant-card-body > h1 {
    color: #555 !important;
    font-size: 1.1rem;
    text-align: center;
}

.content-options > .ant-card:last-child {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.content-options .ant-card-cover {
    margin-top: 2rem;
}

@media screen and (min-width: 769px) {
    .mobile-only {
        display: none !important;
    }

    .footer {
        display: none !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .content {
        margin: 0 1vw;
    }

    .site-search {
        left: 5vw !important;
        max-width: 90vw !important;
        min-width: 90vw !important;
        width: 90vw !important;
    }

    .content-searchbar {
        margin: 1rem 4vw;
        width: 90vw !important;
    }
}

@media screen and (max-width: 768px) {
    .layout-home {
        background-size: 100vw 150px !important;
    }

    .layout {
        background-size: 100vw 64px !important;
    }

    .searchbar img {
        display: none;
    }

    .news__links {
        display: none;
    }

    .ant-menu-dark {
        background: none !important;
    }

    .content {
        margin: 0;
        padding: 0 1rem 66px 1rem !important;
    }

    .layout__header-menu {
        width: 30px;
    }

    .site-layout-content > .ant-card-body {
        padding: 0;
    }

    .content-searchbar {
        width: calc(100vw - 4rem) !important;
        margin: 1rem;
    }

    .content-options {
        display: none;
    }

    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        padding: 0 !important;
    }

    .footer > .ant-card {
        flex: 1;
        border-radius: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;
    }

    .footer__card-icon {
        font-size: 1.25rem;
    }

    .footer > .ant-card > .ant-card-body {
        display: none;
    }

    .footer > .ant-card:hover, .active-underline {
        box-shadow: inset 0 -5px 0 #D1522E;
    }

    .site-search {
        left: 5vw !important;
        max-width: 90vw !important;
        min-width: 90vw !important;
        width: 90vw !important;
    }
}

.google-search {
    color: black;
    cursor: pointer;
    display: block;
}

.google-search > img {
    height: 0.75rem;
    margin-right: 0.25rem;
}
