.news {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.news__new {
    align-self: stretch;
}

.news__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    min-width: 100%;
}

.news__wrapper {
    align-self: stretch;
    flex: 1 auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
}

.news__container {
    flex: 1 auto;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.loader {
    width: 100%;
}

.news__card {
    width: 100%!important;
    align-self: stretch!important;
}

.news__card p {
    font-size: 1rem!important;
    color: #222;
}

@media (max-width: 768px) {
    .news__content {
        flex-direction: column;
        gap: 0.5rem;
    }

    .news__card {
        width: 100%!important;
    }

    .news__content {
        width: 100%!important;
    }
}
