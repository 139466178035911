.training {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.training__new {
    align-self: stretch;
}

.training__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    min-width: 100%;
}

.loader {
    width: 100%;
}

.training__card {
    width: calc(50% - 0.5rem)!important;
    align-self: stretch;
}

.training__card p {
    font-size: 1rem!important;
    color: #222;
}

.training__searchbar {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.training__searchbar .ant-input, .training__searchbar .ant-input-affix-wrapper {
    background: white!important;
}

.training__add__button {
    margin-right: 1rem;
    margin-top: 4px;
}

@media (max-width: 768px) {
    .training__content {
        flex-direction: column;
        gap: 0.5rem;
    }

    .training__card {
        width: 100%!important;
    }

    .training__content {
        width: 100%!important;
    }

    .training__searchbar > input {
        width: 100%!important;
    }
}
