.news__card {
    background: white;
    text-align: justify;
}

.news__card span {
    font-style: italic;
}

.news__card p {
    color: #333333;
}

.avatar {
    width: 64px;
    height: 64px;
}

@media screen and (min-width: 768px) {
    .mobile-only {
        display: none!important;
    }
}

@media screen and (max-width: 768px) {
    .avatar {
        display: none!important;
    }
}
