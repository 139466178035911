.software {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.software__form {
    min-width: 100%;
}

.software__field {
    background: white!important;
}
