.software {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.software__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    min-width: 100%;
}

.software__content .ant-empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 100%;
}

.software__searchbar {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.software__searchbar .ant-input, .software__searchbar .ant-input-affix-wrapper {
    background: white!important;
}

@media screen and (max-width: 768px) {
    .software__content {
        flex-direction: column;
        gap: 0.5rem;
    }

    .software__searchbar > input {
        width: 100%!important;
    }
}
